var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bigbox"},[_c('el-form',{ref:"addData",attrs:{"model":_vm.addData,"size":"small","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"产品类别","prop":"target","rules":{ required: true, message: '请输入产品类别', trigger: 'blur' }}},[_c('el-input',{staticStyle:{"width":"250px"},model:{value:(_vm.addData.target),callback:function ($$v) {_vm.$set(_vm.addData, "target", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addData.target"}})],1),_c('el-form-item',{attrs:{"label":"背景图：","prop":"image","rules":{ required: true, message: '请上传背景图', trigger: 'blur' }}},[_c('div',{staticClass:"upload-image"},[_c('el-upload',{staticClass:"avatar-uploader1",attrs:{"action":_vm.projectName + '/common/backstage/file/fileupload',"headers":_vm.headers,"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.addData.image)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(_vm.addData.image)}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1)]),_c('div',{staticClass:"list-box"},[_c('el-form-item',{attrs:{"label":"二级分类：","prop":"radio","rules":{ required: true, message: '请选择' }}},[_c('el-radio',{attrs:{"label":"有"},on:{"change":_vm.radioChange},model:{value:(_vm.addData.radio),callback:function ($$v) {_vm.$set(_vm.addData, "radio", $$v)},expression:"addData.radio"}},[_vm._v("有")]),_c('el-radio',{attrs:{"label":"无"},on:{"change":_vm.radioChange},model:{value:(_vm.addData.radio),callback:function ($$v) {_vm.$set(_vm.addData, "radio", $$v)},expression:"addData.radio"}},[_vm._v("无")])],1)],1),_c('div',{staticClass:"list-box1"},_vm._l((_vm.addData.lanhuProductCategoryAddRequstList),function(item,index){return _c('div',{key:index,staticClass:"list-box"},[(
            _vm.addData.lanhuProductCategoryAddRequstList.length >= 2 &&
            index >= 1 &&
            _vm.addData.radio == '有'
          )?_c('div',{staticClass:"img-delete",on:{"click":function($event){return _vm.deletebtnClick(index)}}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("./img/deletebtn.png")}})]):_vm._e(),(_vm.addData.radio == '有' && index == 0)?_c('div',{staticClass:"img-add",on:{"click":_vm.addbtnClick}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("./img/add.png")}})]):_vm._e(),(_vm.addData.radio == '有')?_c('el-form-item',{attrs:{"prop":'lanhuProductCategoryAddRequstList.' + index + '.target',"rules":[{ required: true, message: '请输入二级分类' }]}},[_c('el-input',{staticStyle:{"width":"210px"},attrs:{"placeholder":"请输入"},model:{value:(item.target),callback:function ($$v) {_vm.$set(item, "target", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.target"}})],1):_vm._e()],1)}),0),_c('div',{staticClass:"list-box"},[_c('el-form-item',{attrs:{"label":"空间配置：","prop":"radio1","rules":{ required: true, message: '请选择' }}},[_c('el-radio',{attrs:{"label":"有"},on:{"change":_vm.radioChange1},model:{value:(_vm.addData.radio1),callback:function ($$v) {_vm.$set(_vm.addData, "radio1", $$v)},expression:"addData.radio1"}},[_vm._v("有")]),_c('el-radio',{attrs:{"label":"无"},on:{"change":_vm.radioChange1},model:{value:(_vm.addData.radio1),callback:function ($$v) {_vm.$set(_vm.addData, "radio1", $$v)},expression:"addData.radio1"}},[_vm._v("无")])],1)],1),_c('div',{staticClass:"list-box1"},_vm._l((_vm.addData.lanhuPlaces),function(item,index){return _c('div',{key:index,staticClass:"list-box"},[(
            _vm.addData.lanhuPlaces.length >= 2 &&
            index >= 1 &&
            _vm.addData.radio1 == '有'
          )?_c('div',{staticClass:"img-delete",on:{"click":function($event){return _vm.deletebtnClick1(index)}}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("./img/deletebtn.png")}})]):_vm._e(),(_vm.addData.radio1 == '有' && index == 0)?_c('div',{staticClass:"img-add",on:{"click":_vm.addbtnClick1}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("./img/add.png")}})]):_vm._e(),(_vm.addData.radio1 == '有')?_c('el-form-item',{attrs:{"prop":'lanhuPlaces.' + index + '.placeName',"rules":[{ required: true, message: '请输入空间配置' }]}},[_c('el-input',{staticStyle:{"width":"210px"},attrs:{"placeholder":"请输入"},model:{value:(item.placeName),callback:function ($$v) {_vm.$set(item, "placeName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.placeName"}})],1):_vm._e()],1)}),0),_c('div',{staticClass:"list-box"},[_c('el-form-item',{attrs:{"label":"主流品牌：","prop":"radio2","rules":{ required: true, message: '请选择' }}},[_c('el-radio',{attrs:{"label":"有"},on:{"change":_vm.radioChange2},model:{value:(_vm.addData.radio2),callback:function ($$v) {_vm.$set(_vm.addData, "radio2", $$v)},expression:"addData.radio2"}},[_vm._v("有")]),_c('el-radio',{attrs:{"label":"无"},on:{"change":_vm.radioChange2},model:{value:(_vm.addData.radio2),callback:function ($$v) {_vm.$set(_vm.addData, "radio2", $$v)},expression:"addData.radio2"}},[_vm._v("无")])],1)],1),_c('div',{staticClass:"list-box1"},_vm._l((_vm.addData.brandNameList),function(item,index){return _c('div',{key:index,staticClass:"list-box"},[(
            _vm.addData.brandNameList.length >= 2 &&
            index >= 1 &&
            _vm.addData.radio2 == '有'
          )?_c('div',{staticClass:"img-delete",on:{"click":function($event){return _vm.deletebtnClick2(index)}}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("./img/deletebtn.png")}})]):_vm._e(),(_vm.addData.radio2 == '有' && index == 0)?_c('div',{staticClass:"img-add",on:{"click":_vm.addbtnClick2}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("./img/add.png")}})]):_vm._e(),(_vm.addData.radio2 == '有')?_c('el-form-item',{attrs:{"prop":'brandNameList.' + index + '.brandName',"rules":[{ required: true, message: '请输入主流品牌' }]}},[_c('el-input',{staticStyle:{"width":"210px"},attrs:{"placeholder":"请输入"},model:{value:(item.brandName),callback:function ($$v) {_vm.$set(item, "brandName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.brandName"}})],1):_vm._e()],1)}),0)],1),_c('div',{staticClass:"footer-btn"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.cancelClick}},[_vm._v("取 消")]),(_vm.id)?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.editSure}},[_vm._v("确 定")]):_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.addSure}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }