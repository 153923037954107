<template>
  <div class="bigbox">
    <el-form :model="addData" size="small" label-width="100px" ref="addData">
      <el-form-item
        label="产品类别"
        prop="target"
        :rules="{ required: true, message: '请输入产品类别', trigger: 'blur' }"
      >
        <el-input v-model.trim="addData.target" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item
        label="背景图："
        prop="image"
        :rules="{ required: true, message: '请上传背景图', trigger: 'blur' }"
      >
        <div class="upload-image">
          <el-upload
            class="avatar-uploader1"
            :action="projectName + '/common/backstage/file/fileupload'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="addData.image"
              :src="
                projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(addData.image)
              "
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </el-form-item>
      <div class="list-box">
        <el-form-item
          label="二级分类："
          prop="radio"
          :rules="{ required: true, message: '请选择' }"
        >
          <el-radio v-model="addData.radio" label="有" @change="radioChange"
            >有</el-radio
          >
          <el-radio v-model="addData.radio" label="无" @change="radioChange"
            >无</el-radio
          >
        </el-form-item>
      </div>
      <div class="list-box1">
        <div
          v-for="(item, index) in addData.lanhuProductCategoryAddRequstList"
          :key="index"
          class="list-box"
        >
          <div
            class="img-delete"
            v-if="
              addData.lanhuProductCategoryAddRequstList.length >= 2 &&
              index >= 1 &&
              addData.radio == '有'
            "
            @click="deletebtnClick(index)"
          >
            <img src="./img/deletebtn.png" style="width: 30px; height: 30px" />
          </div>
          <div
            class="img-add"
            v-if="addData.radio == '有' && index == 0"
            @click="addbtnClick"
          >
            <img src="./img/add.png" style="width: 30px; height: 30px" />
          </div>
          <el-form-item
            v-if="addData.radio == '有'"
            :prop="'lanhuProductCategoryAddRequstList.' + index + '.target'"
            :rules="[{ required: true, message: '请输入二级分类' }]"
          >
            <el-input
              v-model.trim="item.target"
              style="width: 210px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="list-box">
        <el-form-item
          label="空间配置："
          prop="radio1"
          :rules="{ required: true, message: '请选择' }"
        >
          <el-radio v-model="addData.radio1" label="有" @change="radioChange1"
            >有</el-radio
          >
          <el-radio v-model="addData.radio1" label="无" @change="radioChange1"
            >无</el-radio
          >
        </el-form-item>
      </div>
      <div class="list-box1">
        <div
          v-for="(item, index) in addData.lanhuPlaces"
          :key="index"
          class="list-box"
        >
          <div
            class="img-delete"
            v-if="
              addData.lanhuPlaces.length >= 2 &&
              index >= 1 &&
              addData.radio1 == '有'
            "
            @click="deletebtnClick1(index)"
          >
            <img src="./img/deletebtn.png" style="width: 30px; height: 30px" />
          </div>
          <div
            class="img-add"
            v-if="addData.radio1 == '有' && index == 0"
            @click="addbtnClick1"
          >
            <img src="./img/add.png" style="width: 30px; height: 30px" />
          </div>
          <el-form-item
            v-if="addData.radio1 == '有'"
            :prop="'lanhuPlaces.' + index + '.placeName'"
            :rules="[{ required: true, message: '请输入空间配置' }]"
          >
            <el-input
              v-model.trim="item.placeName"
              style="width: 210px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="list-box">
        <el-form-item
          label="主流品牌："
          prop="radio2"
          :rules="{ required: true, message: '请选择' }"
        >
          <el-radio v-model="addData.radio2" label="有" @change="radioChange2"
            >有</el-radio
          >
          <el-radio v-model="addData.radio2" label="无" @change="radioChange2"
            >无</el-radio
          >
        </el-form-item>
      </div>
      <div class="list-box1">
        <div
          v-for="(item, index) in addData.brandNameList"
          :key="index"
          class="list-box"
        >
          <div
            class="img-delete"
            v-if="
              addData.brandNameList.length >= 2 &&
              index >= 1 &&
              addData.radio2 == '有'
            "
            @click="deletebtnClick2(index)"
          >
            <img src="./img/deletebtn.png" style="width: 30px; height: 30px" />
          </div>
          <div
            class="img-add"
            v-if="addData.radio2 == '有' && index == 0"
            @click="addbtnClick2"
          >
            <img src="./img/add.png" style="width: 30px; height: 30px" />
          </div>
          <el-form-item
            v-if="addData.radio2 == '有'"
            :prop="'brandNameList.' + index + '.brandName'"
            :rules="[{ required: true, message: '请输入主流品牌' }]"
          >
            <el-input
              v-model.trim="item.brandName"
              style="width: 210px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="footer-btn">
      <el-button size="small" @click="cancelClick">取 消</el-button>
      <el-button size="small" type="primary" @click="editSure" v-if="id"
        >确 定</el-button
      >
      <el-button size="small" type="primary" @click="addSure" v-else
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
import { categoryadd, categoryedit, categorydetails } from "../../api/index";
import { projectName } from "../../util/config";
export default {
  data() {
    return {
      projectName: projectName,
      headers: { sessionId: sessionStorage.getItem("access_token") },
      addData: {
        target: "", //产品名称
        image: "",
        radio: "有",
        radio1: "有",
        radio2: "有",
        lanhuProductCategoryAddRequstList: [{ target: "" }],
        lanhuPlaces: [{ placeName: "" }],
        brandNameList: [{ brandName: "" }],
      },
      id: "",
      current: null,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.current = this.$route.query.current;
      this.id = this.$route.query.id;
      this.deitData(this.id);
    }
  },
  methods: {
    // 取消
    cancelClick() {
      this.$router.push({ name: "typeManagement",query:{current:this.current}});
      // this.dialogVisible = false;
      this.$refs.addData.resetFields();
      this.addData = {
        target: "", //产品名称
        image: "",
        radio: "有",
        radio1: "有",
        radio2: "有",
        lanhuProductCategoryAddRequstList: [{ target: "" }],
        lanhuPlaces: [{ placeName: "" }],
        brandNameList: [{ brandName: "" }],
      };
    },
    handleAvatarSuccess(res, file) {
      this.addData.image = res.data.nginxFileReadPath;
    },
    beforeAvatarUpload(file) {},

    radioChange(val) {
      if (val == "无") {
        this.addData.lanhuProductCategoryAddRequstList = [{ target: "" }];
      } else if (
        val == "有" &&
        this.addData.lanhuProductCategoryAddRequstList.length == 0
      ) {
        this.addData.lanhuProductCategoryAddRequstList.push({ target: "" });
      }
    },
    radioChange1(val) {
      if (val == "无") {
        this.addData.lanhuPlaces = [{ placeName: "" }];
      } else if (val == "有" && this.addData.lanhuPlaces.length == 0) {
        this.addData.lanhuPlaces.push({ placeName: "" });
      }
    },
    radioChange2(val) {
      if (val == "无") {
        this.addData.brandNameList = [{ placeName: "" }];
      } else if (val == "有" && this.addData.brandNameList.length == 0) {
        this.addData.brandNameList.push({ brandName: "" });
      }
    },
    addSure() {
      if (this.addData.radio == "无") {
        this.addData.lanhuProductCategoryAddRequstList = [];
      }
      if (this.addData.radio1 == "无") {
        this.addData.lanhuPlaces = [];
      }
      if (this.addData.radio2 == "无") {
        this.addData.brandNameList = [];
      }
      let data = {
        ...this.addData,
        type: 0,
      };
      this.$refs.addData.validate((valid) => {
        if (valid) {
          categoryadd(data).then((res) => {
            if (res.code == 1) {
              this.$message.success("新增成功！");
              this.$router.push({ name: "typeManagement" });
              // this.getTableDataList()
              // this.dialogVisible = false;
              this.$refs.addData.resetFields();
              this.addData = {
                target: "", //产品名称
                image: "",
                radio: "有",
                radio1: "有",
                radio2: "有",
                lanhuProductCategoryAddRequstList: [{ target: "" }],
                lanhuPlaces: [{ placeName: "" }],
                brandNameList: [{ brandName: "" }],
              };
            }
          });
        }
      });
    },
    addbtnClick() {
      this.addData.lanhuProductCategoryAddRequstList.push({ target: "" });
    },
    addbtnClick1() {
      this.addData.lanhuPlaces.push({ placeName: "" });
    },
    addbtnClick2() {
      this.addData.brandNameList.push({ brandName: "" });
    },
    deletebtnClick(index) {
      let dataArr = this.addData.lanhuProductCategoryAddRequstList;
      if (dataArr.length >= 2) {
        this.addData.lanhuProductCategoryAddRequstList.splice(index, 1);
      } else {
      }
    },
    deletebtnClick1(index) {
      let dataArr = this.addData.lanhuPlaces;
      if (dataArr.length >= 2) {
        this.addData.lanhuPlaces.splice(index, 1);
      } else {
      }
    },
    deletebtnClick2(index) {
      let dataArr = this.addData.brandNameList;
      if (dataArr.length >= 2) {
        this.addData.brandNameList.splice(index, 1);
      } else {
      }
    },
    // 编辑
    deitData(id) {
      console.log(id, "id");
      categorydetails({ id: id }).then((res) => {
        if (res.code == 1) {
          // this.dialogVisible = true;
          let data = res.data || {};
          this.addData = { ...data } || {};
          if (
            data.lanhuProductCategoryAddRequstList &&
            data.lanhuProductCategoryAddRequstList.length > 0
          ) {
            this.$set(this.addData, "radio", "有");
          } else {
            this.$set(this.addData, "radio", "无");
            //    this.addData.lanhuProductCategoryAddRequstList.push({target:''})
          }
          if (data.lanhuPlaces && data.lanhuPlaces.length > 0) {
            this.$set(this.addData, "radio1", "有");
          } else {
            this.$set(this.addData, "radio1", "无");
            //    this.addData.lanhuProductCategoryAddRequstList.push({target:''})
          }
          if (data.brandNameList && data.brandNameList.length > 0) {
            this.$set(this.addData, "radio2", "有");
          } else {
            this.$set(this.addData, "radio2", "无");
            //    this.addData.lanhuProductCategoryAddRequstList.push({target:''})
          }
          console.log(this.addData, "this.addData");
        }
      });
    },
    editSure() {
      if (this.addData.radio == "无") {
        this.addData.lanhuProductCategoryAddRequstList = [];
      }
      if (this.addData.radio1 == "无") {
        this.addData.lanhuPlaces = [];
      }
      if (this.addData.radio2 == "无") {
        this.addData.brandNameList = [];
      }
      let data = {
        ...this.addData,
        id: this.addData.id,
        type: 0,
      };
      console.log(data, "data");
      this.$refs.addData.validate((valid) => {
        if (valid) {
          categoryedit(data).then((res) => {
            if (res.code == 1) {
              this.$message.success("编辑成功！");
              // this.getTableDataList()
              // this.dialogVisible = false;
              this.$router.push({ name: "typeManagement",query:{current:this.current}});
              this.$refs.addData.resetFields();
              this.addData = {
                target: "", //产品名称
                image: "",
                radio: "有",
                radio1: "有",
                lanhuProductCategoryAddRequstList: [{ target: "" }],
                lanhuPlaces: [{ placeName: "" }],
                brandNameList: [{ brandName: "" }],
              };
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bigbox {
  padding: 30px;
}
.footer-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 208px;
  height: 108px;
  line-height: 108px;
  text-align: center;
  border: 1px dashed;
}
.avatar {
  width: 208px;
  height: 108px;
  display: block;
}
.list-box1 {
  // position: relative;
  overflow-y: auto;
  max-height: 200px;
}
.list-box {
  position: relative;
}
.img-add {
  position: absolute;
  left: 340px;
  top: 0px;
  cursor: pointer;
  z-index: 9;
}
.img-delete {
  position: absolute;
  left: 340px;
  top: 0px;
  cursor: pointer;
  z-index: 9;
}
</style>